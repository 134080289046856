.add-button {
    text-align: center;
    cursor: pointer;
    background: black;
    padding: 5px;
    width: 20px;
    color: white;
}

.text-input {
    font-size: 20px;
}