.decommission-container {
    margin-top:-20px;
    margin-left:50px;
    margin-right: 50px;
}

.decomm_heading {
    text-align: center;
    font-size: 35px;
    /* margin-top : 65px; */
}
.recomm-button {
    background: #449d44;
    color : white;
    /* margin-left : 55%; */
    padding : 10px;
    font-size : 16px;
    margin-bottom: 5px;
    cursor: pointer;
}
.global-decomm-response {
    overflow: scroll;
    padding: 5px;
    width: 70%;
    margin: 0px auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background: #f1f1f1;
}