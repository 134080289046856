.mortise-lock-container {
    margin-top:20px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    
}
.input-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 2px solid #123;
}
.input-box {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 18px;
    padding:5px;
    height: 60%;
    width: 32vh;
    outline:0;
    border: none;
}
#mortise-button {
    background: black;
    color: white;
    border-radius: 0;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
#mortise-button:hover {
    background: rgb(138, 138, 138);
    color: black;
}
.battery-align {
    text-align: center;
}
.filter-container {
    padding-bottom:10px;
    display: flex;
    justify-content: flex-start;
}
.filter-heading {
    font-size:18px;
    font-weight: 700;
    margin-right:10px;
}
.filter-bar {
    border:2px solid #123;
}
.filter-bar {
    font-size:15px;
    width: 200px;
    height: 20px;
    padding: 3px;
    outline:0;
}
.progress-container {
    display: flex;
}
.progress-bar {
    padding-right:10px;
}
.hyphen-line {
    display: flex;
    align-content: center;
    justify-content: center;
    width:15px;
    height: 3px;
    background: #123;
}
.battery-align {
    text-align: center;
}
.hyphen-container {
    display: flex;
    align-items: center;
    justify-content: center;
} 	
.mortise-table-container {
    width:1000px;
}
.mortise-table-container thead tr td{
    background: #bdbdbd;
    border-color: #a5a5a5;
    cursor: default;
    font-weight: 600;
    text-align: center;
}

.dur-dropdown {
    width: 60%;
    align-content: center;
    /* display: flex; */
    z-index: 5000 !important;
    /* position: relative; */
}