.login-main {
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;
  background-image: url("../../assets/images/bg-3.jpg") ;
  background-size: cover;
  padding-top: 100px;
}
.login-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}
.login-logo-container {
  width: 290px;
  height: 70px;
  background-image:url("../../assets/images/OYO Mesh Logo-04.png");
  background-size:290px 70px;
  background-repeat: no-repeat;
}
.login-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 20px auto;
  max-height: 85vh;
}
.login-error {
  margin-top: 10px;
  padding-top: 5px;
  font-family: Brown-Regular;
  color: #ff0000;
}
.login-input-container {
  display: flex;
  flex-direction: column;
  height: 150px;
  flex-wrap: wrap;
}
.login-input {
  font-size: 27px;
  width: 24rem;
  border-radius: 2px;
  background:#ededed;
  outline: none;
  margin-top: 30px;
}
.login-input.visited {
  background:#ededed;
}
.login-button {
  background: #2e2e2e;
  width: 150px;
  height: 50px;
  color: white;
  font-size: 18px;
  margin: 10px 10px 15px;
  cursor: pointer;
  box-shadow: 0px 2px 9px 1px rgba(201, 201, 201, 0.9);
  outline: none;
}
.login-button:hover {
  background: black;
  box-shadow: 0px 2px 9px 1px rgba(150, 150, 150, 0.7);
}
.product-type-list {
  width: 250px;
  height: 40px;
}
@media screen and (max-width: 800px) {
  .login-main {
    background: #fdfdfd;
    background-image:none;
    padding-top: 50px;
  }
  .login-logo-container {
    width: 170px;
    height: 40px;
    background-size:170px 40px;
    margin-bottom: 20px;
  }
  .login-input {
    font-size: 27px;
    width: 20rem;
    background:#ededed;
    outline: none;
  }
  .login-button {
    width: 150px;
    height: 40px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 5px 1px grey;
  }
}
.product-type-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
}
#oyo {
  font-family: Brown-light;
  color: #bf1300;
}
#mesh {
  font-family: Brown-Regular;
  color: black;
}