.hotel-Admin-layout-container-layout {
  text-align: center;
}
.hotel-Admin-layout-container {
  width: auto;
  text-align: right;
  margin: auto;
  display: inline-block;
}
.hotel-Admin-layout-container-header {
  /* display: inline-block; */
  text-align: right;
}
.hotel-Admin-layout {
    border-collapse: collapse;
    /* width: 134%; */
    height: 100px;
    margin: 0px 0px 20px 0px;
  }

.tableAlign{
  width: 100%;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}
@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}
.table-option-heading {
  text-align: center;
  /* width: 134%; */
  /* margin-right: 6%; */
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.refresh-container{
  width:134%;
  text-align: right
}

.hotel-Admin-layout td, .hotel-Admin-layout th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
/* .hotel-Admin-layout tbody tr:nth-child(even){background-color: #f2f2f2;} */
  
.hotel-Admin-layout tbody tr:hover {background-color: #F5F5F5; cursor: pointer;}
  
.hotel-Admin-layout thead tr {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color:#f1f1f1;
    cursor: pointer;
 }
 .activity-heading {
   display: flex;
   /* height: 45px; */
   justify-content: center;
 }
 .activity-label {
    background: #f1f1f1;
    text-align: left;
    letter-spacing: 1px;
    width: 100%;
    padding: 10px 8px;
    font-size: 18px;
    font-family: 'Arial';
    cursor: default;
    margin: 0px 0px 20px 0px;
}
.fa {
  font-size: 35px !important;
  color: white;
  padding-bottom: 2px;
}
.close-button {
  background: black;
  float: right;
  border: none;
  cursor: pointer;
}
.close-button:hover{
  background: red;
}