.video {
    border: 1px solid #f1f1f1;
    width: 41vw!important;
    height: 60vh !important;
    max-width: 555px;
    max-height: 348px;
    min-width: 320px;
}
.video-container {
    display: inline-block;
    width: 50%;
}
.flex-row-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 10px 20px 10px;
}