.Hotel-Details {
  display: flex;
  padding: 10px;
  justify-content: center;
  background: #c1c1c1;
}

.Wifi-Details {
  display: flex;
  padding: 10px;
  justify-content: center;
  background: #f5f5f5;
}

.Hotel-Details-Text, .Wifi-Details-Text {
  margin-left: 15px;
  font-size: 15px;
  font-family: 'Brown-regular';
}

.activity-form {
  display: block;
  text-align: center;
}

.form-input{
  padding: 5px;
  font-size: 15px;
}

.Activity-Response {
  display: flex;
  justify-content: center;
}

.submit-button-activity {
  cursor: pointer;
  background: #5cb85c;
  color: white;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  margin-right: 5px;
}

.submit-button-activity:hover {
  background: #449d44;
  color: white;
}

.dropdown {
  background: #fff;
  color: black;
  font-size: inherit;
  cursor: pointer;
}

.form-label {
  font-size : 18px;
  margin: 10px;
}