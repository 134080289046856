.tabs {
    display: inline;
    /* background: #999; */
}
.tabs-header {
    margin-bottom: 20px;
}
#tab1, #tab2, #tab3{
    display: none;
}
input + label {
    border: 2px solid #999;
    background: #EEE;
    padding: 4px 12px;
    border-radius: 4px 4px 0 0;
    position: relative;
    top: 1px;
    font-size: 18px;
}
input ~ .tab { 
    display: none 
} 
#tab1:checked ~ .tab.content1, #tab2:checked ~ .tab.content2, #tab3:checked ~ .tab.content3 { 
    display : block; 
    margin-top: 20px;
}
input:checked + label {
    background: #FFF;
    border-bottom: 1px solid transparent;
}