.decomm-city-layout{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 134%;
    overflow: scroll;
    display: inline-flexbox;
}
.tableAlign {
    margin-top:20px;
    width: 100%;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s;
}
.decomm-button-container {
    /* margin-left : 58%; */
    margin-bottom: 10px;
}
.popup-content  {
    width: auto !important;
    height: 350px !important;
    min-width: 300px;
    overflow: auto;
}