.menubar {
    margin:0px;
    padding: 0px;
    background: #e9e9e9;
    display: flex;
}

.menubar li {
    padding-top:10px;
    padding-bottom: 10px;
    /* padding:10px; */
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transition: none;
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size:15px;
    color:rgb(71, 71, 71);
}

.menubar li:hover {
    background: #cacaca;
    color:rgb(0, 0, 0);
}

.link-hover {
    color:inherit;
    padding:10px;
    text-decoration: none;
}
.link-hover-selected {
    padding: 10px;
    color: #3e2424;
    text-decoration: none;
    border-bottom: 3px solid #ff0000;
}
.menubar li .link-hover-selected {
    margin:0px;
}