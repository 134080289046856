.device-details {
    width: 25%;
    min-width: 300px;
    margin: 0px auto;
    background: #f1f1f1;
    padding: 10px;
}

.device-details-field {
    display: flex;
    flex: 100;
    width: 100%;
    margin-bottom: 10px;
}
.device-details-field .device-details-label {
    flex: 90;
}
.device-details-field .device-details-value {
    flex: 10;
}