.password-details {
        width: 30%;
        margin: auto;
        background: #f1f1f1;
        padding: 20px;
        min-width: 375px;
        box-sizing: border-box;
}
.password-details-error {
    
    text-align: center;
    color: red;
}
.password-content {
    padding: 5px;
    font-size: 20px;
}
.password-content > * {
    word-break: break-all;
    display: inline-block;
}