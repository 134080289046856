.selected {
    background: green;
    color: white;
    padding: 5px;
    overflow: auto;
}
.selected-error {
    /* background: red; */
    color: red;
    padding: 5px;   
}