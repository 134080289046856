body {
    font-family: Lato, sans-serif;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

#react-select {
    width: 40%;
    min-width: 250px;
}

#details-button {
    width: fit-content;
    margin: 15px;
    background-color: rgba(18, 55, 0, 0.88);
}

#top-intro {
    display: flex;
    flex-direction: row;
    text-align: center;
    border: 1px solid black;
    background-color: black;
    color: white;
    font-family: Lato, monospace;
    font-size: 28px;
    font-weight: bold;
}

#hotel-search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin-top: 15px;
}

#bottom-division {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
}

#checkin-table {
    flex: 4;
    padding: 20px;
    height: 450px;
    display: block;
    overflow-y: scroll;
}

#new-checkin {
    display: flex;
    margin-left: 5px;
    flex: 3;
    padding: 15px;
    align-items: center;
}

table {
    font-family: Lato, sans-serif;
    border-collapse: collapse;
    border: none;
    margin-left: 15px;
}

td, th {
    border-bottom: 1px solid #c9c9c9;
    text-align: center;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: rgba(69, 191, 51, 0.31);
}

#checkin-form {
    height: fit-content;
    width: 50%;
    border: 1px solid #d2d2d2;
    position: relative;
    margin-left: 25%;
    border-radius: 10px;
}

#input-name {
    margin-bottom: 5px;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    min-width: 100px;
}

.table-head {
    font-size: 20px;
    font-family: Lato, monospace;
    background-color: #316621;
    color: white;
}
/*: #4C9A2A;*/

.title-text {
    font-size: 20px;
    padding: 10px;
}

#new-checkin-text {
    width: border-box;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #1E5631;
    color: white;
}

#form-data {
    margin: 20px;
}

.table-button {
    font-size: 19px;
    font-family: Lato, sans-serif, monospace;
}

#logout-button {
    width: fit-content;
    padding-right: 5px;
    padding-top: 10px;
}

#title {
    flex: 5;
    align-content: center;
}

#loading-indicator {
    margin-top: 50px;
    text-align: center;
}
