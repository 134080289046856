table {
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid whitesmoke;
  }
  
  table td,
  table th {
    padding: 12px;
  }
  table th {
    font-size: 16px;
  }
  table tr {
    border-top: 1px solid whitesmoke;
    height: 60px;
  }
  
  table tr:nth-child(even) {
    background-color: rgb(237, 235, 232);
  }
  
  table tr:hover {
    background-color: rgb(238, 238, 238);
  }
  
  table th,
  tfoot {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f0f0f0;
    color: rgba(0, 0, 0, 0.6);
  }
  
  td {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
  
  .red-color {
    color: red;
  }
  
  .green-color {
    color: green;
  }