.menubar-switch {
    margin:0px;
    padding: 0px;
    background: #e9e9e9;
    display: flex;
    word-break: break-all;
    /* width:100%; */
}
.menubar-switch li {
    margin:0;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transition: none;
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size:15px;
    color:rgb(71, 71, 71);
}
.menubar-switch > li:hover {
    background: #cacaca;
    color:rgb(0, 0, 0);
}
.menubar-switch > li:hover ul{
    font-weight: normal;
}
.link-hover-switch {
    color:inherit;
    padding:10px;
    text-decoration: none;
    -webkit-transition: 1s;
    transition: 1s;
    word-break: break-word;
}
.link-hover-switch-selected {
    padding: 10px;
    color: #3e2424;
    text-decoration: none;
    border-bottom: 3px solid #ff0000;
}
.dropdown-menubar .link-hover-switch-selected {
    border-bottom: 2px solid #ff0000;
}
.dropdown-menubar {
    cursor: pointer;
    padding: 10px;
}
.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    margin:0;
    padding-left: 0px;
    z-index: 1;
    display: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 2px 2px rgb(138, 138, 138);
    width:200px;
}
.dropdown-content li {
    border-bottom:2px solid rgb(192, 192, 192);
    margin:0;
    width:200px;
}
.dropdown-content > li a{
    margin:0;
    width:200px;
    text-decoration: none;
    color:rgb(71, 71, 71);
    padding-top:5px;
    padding-bottom:5px;
    padding-right:100px;
}
.dropdown-content > li:nth-child(5) a {
    padding-right:0px;
}
.dropdown-content > li:hover {
    width:200px;
    background: #f0f0f0;
}
.dropdown-content > li:hover a{
    color: black;
}
ul>li:hover>ul {
    display: block;
}