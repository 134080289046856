.decomm-button {
    padding : 20px;
    text-align: center;
}
.global-decomm-button {
    background : #f0ad4e;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding : 5px;
}
.global-decomm-button:hover {
    background : #ec971f;
}
.submit-button-decomm {
    background: #5cb85c;
}
.cancel-button-decomm {
    background: #337ab7;
}
.submit-button-decomm, .cancel-button-decomm {
    margin : 5px;
    /* background: black; */
    color: white;
    font-size : 16px;
    cursor: pointer;
    margin-top: 10px;
    padding : 5px;
    /* width : 17%; */
}
.submit-button-decomm:hover {
    background: #449d44;
}
.cancel-button-decomm:hover {
    background: #286090;
}
.response-container {
    margin-left : 43%;
    margin-top : 10%;
    width:30%;  
    text-align: center;
}
.loading-container {
    margin-left : -15%;
}