.submit-button-bulk-checkin {
    cursor: pointer;
    background: #5cb85c;
    /* color: white; */
    padding: 10px;
    font-size: 16px;
  }

  .activityLayout {
    display: block;
    text-align: center;
    justify-content: center;
    margin: 20px;
    height: 40;
  }

  .form-input {
    padding: 5px;
    display: flex;
    flex: 100 1;
    width: 300px;
    margin: 0px 10px 10px;
    text-align: left;
    box-sizing: border-box;
  }
  .form-input > *{
    flex: 50;
  }

  .text-box {
    width : 60%;
    font-size: 18px;
    outline: none;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .select-rooms {
    margin-bottom: 10px;
  }
  .room-checkbox {
      padding : 5px;
      font-size : 20px;
      cursor: pointer;
  }

  .bulk-loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid red;
    width: 20px;
    height: 20px;
    animation: spin 1.25s linear infinite; 
    margin-left: 48%;
    margin-top: 2%;
  }