.top-bar {
    width: 100vw;
    height: 60px;
    background: #161516;
    display: flex;
    justify-content: space-between;
    position: -webkit-sticky;
    z-index: 20;
}

.top-bar-logo-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    width: auto;
}
.top-bar-logo {
    margin-left: 10px;
    width: 150px;
    height: 30px;
    background-image:url("../../assets/images/OYO Mesh Logo-03.png");
    background-size: 150px 30px;
    background-repeat: no-repeat;
}
.top-bar-divider {
    width: 2px;
    height: 25px;
    background: #fff;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.top-bar-text {
    margin-top: 10px;
    font-weight: 600;
    pointer-events: none;
    user-select: none;
}
.top-bar-userbutton {
    background-color: #161417;
    color: white;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    border: none;
    cursor: pointer;
    outline: none;
}
.top-bar-userbutton:hover {
    background-color: #272627;
}
.top-bar-username {
    padding-left: 10px;
}

@media screen and (max-width : 800px) {
    .top-bar {
        display: grid;
        grid-template-columns: 88% 12%;
        height: 50px;
    }
    .top-bar-logo {
        width: 140px;
        height: 30px;
        background-size: 140px 30px;
    }
    .top-bar-divider, .top-bar-text {
        display: none;
    }
    .top-bar-username {
        display: none;
    }
    .top-bar-usericon {
        font-size: 30px;
    }
}
@media screen  and (min-width: 800px) {
    .top-bar-usericon {
        display: none;
    }
}

.user-dropdown {
    position: relative;
    display: inline-block;
}

.user-content {
    display: none;
    position: absolute;
    right: 1;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.user-content button {
    color: black;
    background: none;
    color: inherit;
    border: none;
    padding: 12px 12px;
    margin: 0px 16px;
    text-decoration: none;
    display: block;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 80%;
    text-align: left;
}

.user-content button:hover {
    background-color: #f1f1f1;
}

.user-dropdown:hover .user-content {
    display: block;
}

.user-dropdown:hover .user-button {
    background-color: #5c5b5b;
}