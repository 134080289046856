.search-container {
    /* width: 60%; */
    display: flex;
    flex-direction: column;
    /* padding: 0px 10px 20px 30px; */
    /* margin-left: 18%; */
}
.search-bar {
    margin-top:10px;
    width: 50%;
    margin: 0px auto 20px;
    min-width: 350px;
    display: inline-block;
    cursor: pointer;
}
.tooltip {
    position: relative;
}
.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid red;
    width: 15vh;
    height: 15vh;
    -webkit-animation: spin 1.25s linear infinite;
    animation: spin 1.25s linear infinite;
    margin: 0px auto 20px;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.bulk-access-button, .refresh-button {
    background-color: #5cb85c;
    color: white;
    padding: 13px 70px;
    font-size: 15px;
    cursor: pointer;
}
.bulk-access-button {
    /* width: 20%; */
    /* margin-left: 35%; */
    display: inline-block;
}
.fa {
    font-size : 15px !important;
}
.bulk-button {
    text-align: center;
}